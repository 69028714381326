<template>
  <el-dialog
    v-model="showSaleDetailDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-valuation-sale-detail-dialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-valuation-sale-detail-dialog-header__title">
        {{ t('title.saleDetail') }}
      </h4>
    </template>

    <div v-loading="dialogLoading" class="elv-valuation-sale-detail-dialog-content">
      <div class="elv-valuation-sale-detail-title-container">
        <h4 class="elv-valuation-sale-detail-title">{{ saleDetail?.journalType?.name }}</h4>
        <p>
          {{ saleDetail?.journalActivity?.journalNo }}<span>{{ saleDetail?.journalActivity?.referenceNo }}</span>
        </p>
      </div>
      <ul class="elv-valuation-sale-detail-info">
        <li>
          <span>{{ t('common.date') }}</span>
          <p>
            {{
              dayjs(saleDetail?.datetime)
                .tz(saleDetail?.journalActivity?.timezone ?? 'UTC')
                .format('YYYY/MM/DD')
            }}
          </p>
        </li>
        <li>
          <span
            >{{ t('report.Quantity') }} ({{ saleDetail?.underlyingCurrency?.showSymbol ?? saleDetail.currency }})</span
          >
          <p>{{ formatNumber(saleDetail?.soldQuantity, 20) }}</p>
        </li>
        <li>
          <span>{{ t('project.costBasis.costbasis') }}</span>
          <p
            :title="
              fieldValueFormat(
                saleDetail?.soldCostBasis ?? '',
                {
                  price: true,
                  keepPoint: true,
                  symbol: `${
                    entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                  }`
                },
                'NUMBER'
              )
            "
          >
            {{
              saleDetail?.soldCostBasis
                ? formatNumberToSignificantDigits(
                    saleDetail?.soldCostBasis,
                    2,
                    `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                  )
                : '-'
            }}
          </p>
        </li>
      </ul>

      <div class="elv-valuation-sale-detail-inventory">
        <div class="elv-valuation-sale-detail-inventory-header">
          <div class="elv-valuation-sale-detail-inventory-header-left">
            <h5 class="elv-valuation-sale-detail-inventory-title">{{ t('common.inventory') }}:</h5>
            <div
              v-if="
                !(
                  entityStore.entityDetail?.inventoryCostBasisMethodology === 'SPECIFIC_ID' &&
                  saleDetail?.costBasisType === 'WAITING'
                )
              "
              class="elv-valuation-sale-detail-inventory-desc"
            >
              <p>
                {{ transformI18n(inventoryCostBasisMethodology) }}
                <span>
                  {{
                    dayjs(saleDetail?.updatedAt)
                      .tz(saleDetail?.journalActivity?.timezone ?? 'UTC')
                      .format('YYYY/MM/DD HH:mm:ss')
                  }}</span
                >
              </p>
            </div>
          </div>
          <elv-button
            v-if="entityStore.entityDetail?.inventoryCostBasisMethodology === 'SPECIFIC_ID'"
            height="32"
            width="219"
            round
            plain
            type="primary"
            @click="onOpenAssignmentMessageBox"
          >
            {{
              saleDetail?.costBasisType === 'MANUAL'
                ? t('button.changeToAutomateAssignment')
                : t('button.changeToManualAssignment')
            }}</elv-button
          >
        </div>
        <el-table
          v-if="
            (saleDetail?.costBasisType === 'RULE' && saleDetail?.inventoryList?.length) ||
            saleDetail?.costBasisType !== 'WAITING'
          "
          ref="tableListRef"
          v-table-skeleton="{ loading: dialogLoading, rows: 3 }"
          :data="saleDetail?.inventoryList ?? []"
          border
          stripe
          class="elv-valuation-record-table"
          header-cell-class-name="elv-valuation-record-table-header__cell"
          header-row-class-name="elv-valuation-record-table-header"
          row-class-name="elv-valuation-record-table-row"
          cell-class-name="elv-valuation-record-table-row__cell"
        >
          <el-table-column
            type="index"
            width="36"
            align="center"
            class-name="elv-valuation-record-table-row__cell-index"
          />
          <el-table-column :label="t('valuation.crypto.inventoryNo')" width="125">
            <template #default="{ row }">
              <VerticalTextCell
                :up-text="row.inventoryNo"
                :up-weight="500"
                :down-text="
                  dayjs(row?.datetime)
                    .tz(row?.journalActivity?.timezone ?? 'UTC')
                    .format('YYYY/MM/DD')
                "
                align="left"
              />
            </template>
          </el-table-column>
          <el-table-column :label="t('report.Journal')">
            <template #default="{ row }">
              <VerticalTextCell
                :up-text="row.journalType?.name"
                :up-weight="500"
                up-font-family="Plus Jakarta Sans"
                :down-text="row.journalActivity?.journalNo"
                align="left"
              />
            </template>
          </el-table-column>

          <el-table-column :label="t('valuation.crypto.quantity')" width="130">
            <template #default="{ row }">
              <TextCell :text="formatNumber(row?.quantity, 20)" align="right" :weight="500" />
            </template>
          </el-table-column>

          <el-table-column :label="t('common.price')" width="130">
            <template #default="{ row }">
              <TextCell
                :text="
                  formatNumberToSignificantDigits(
                    row.purchasePrice,
                    2,
                    `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                  )
                "
                :titleDesc="
                  fieldValueFormat(
                    row.purchasePrice,
                    {
                      price: true,
                      keepPoint: true,
                      symbol: `${
                        entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                      }`
                    },
                    'NUMBER'
                  )
                "
                align="right"
                :weight="500"
              />
            </template>
          </el-table-column>

          <el-table-column :label="t('valuation.crypto.costBasis')" width="130">
            <template #default="{ row }">
              <TextCell
                :text="
                  formatNumberToSignificantDigits(
                    row.costbasis,
                    2,
                    `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                  )
                "
                :titleDesc="
                  fieldValueFormat(
                    row.costbasis,
                    {
                      price: true,
                      keepPoint: true,
                      symbol: `${
                        entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                      }`
                    },
                    'NUMBER'
                  )
                "
                align="right"
                :weight="500"
              />
            </template>
          </el-table-column>
        </el-table>

        <EmptyResult
          v-else
          :img-src="noDataImg"
          width="64px"
          height="64px"
          class="elv-valuation-sale-detail-empty-content"
        >
          <template #content>
            <p>No Inventory matched. Please re-run the cost basis rules.</p>
          </template>
        </EmptyResult>

        <div
          v-if="
            entityStore.entityDetail?.inventoryCostBasisMethodology === 'SPECIFIC_ID' &&
            saleDetail?.costBasisType === 'MANUAL'
          "
          class="elv-valuation-sale-detail-inventory-select"
        >
          <elv-button height="30" width="136" round type="primary" @click="onSelectInventory">
            <SvgIcon name="sources-edit" width="14" height="14" fill="#fff" />
            {{ t('button.selectInventory') }}</elv-button
          >
        </div>
      </div>
    </div>
  </el-dialog>

  <ElvMessageBox
    ref="changeAssignmentMessageBoxRef"
    :showCancelButton="true"
    :cancelButtonText="t('button.cancel')"
    :confirmButtonText="t('button.change')"
    :title="
      saleDetail?.costBasisType === 'MANUAL'
        ? t('button.changeToAutomateAssignment')
        : t('button.changeToManualAssignment')
    "
    :loading="changeLoading"
    @onCancelEvent="onCancelAssignment"
    @onConfirmEvent="onChangeAssignment"
  >
    <template #content>
      <span class="elv-confirm-counterparty">{{
        saleDetail?.costBasisType === 'MANUAL'
          ? t('message.changeToAutomateAssignmentInfo')
          : t('message.changeToManualAssignmentInfo')
      }}</span></template
    >
  </ElvMessageBox>

  <SelectInventoryDialog ref="selectInventoryDialogRef" :current-data="saleDetail" @resetList="fetchSaleDetail()" />
</template>
<script lang="ts" setup>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { isEmpty } from 'lodash-es'
import { ElMessage } from 'element-plus'
import { $t, transformI18n } from '@/i18n'
import timezone from 'dayjs/plugin/timezone'
import ValuationApi from '@/api/ValuationApi'
import { useEntityStore } from '@/stores/modules/entity'
import EmptyResult from '@/components/Base/EmptyResult.vue'
import noDataImg from '@/assets/img/reports/table-built.png'
import { useReportStore } from '@/stores/modules/reports/index'
import SelectInventoryDialog from './SelectInventoryDialog.vue'
import TextCell from '@/components/Base/Table/Cell/TextCell.vue'
import { useUserGlobalStore } from '@/stores/modules/global/user'
import VerticalTextCell from '@/components/Base/Table/Cell/VerticalTextCell.vue'
import { formatNumber, fieldValueFormat, formatNumberToSignificantDigits } from '@/lib/utils'

const props = defineProps({
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

dayjs.extend(utc)
dayjs.extend(timezone)
const { t } = useI18n()
const route = useRoute()
// eslint-disable-next-line no-unused-vars
const reportStore = useReportStore()
const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)
// eslint-disable-next-line no-unused-vars
const userGlobalStore = useUserGlobalStore()

const dialogLoading = ref(false)
const changeLoading = ref(false)
// eslint-disable-next-line no-unused-vars
const showSaleDetailDialog = ref(false)
const saleDetail: any = ref({})

const selectInventoryDialogRef = useTemplateRef('selectInventoryDialogRef')
const changeAssignmentMessageBoxRef = useTemplateRef('changeAssignmentMessageBoxRef')

// eslint-disable-next-line no-unused-vars
const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const inventoryCostBasisMethodology = computed(() => {
  switch (entityStore.entityDetail?.inventoryCostBasisMethodology) {
    case 'FIFO':
      return $t('project.fifo')
    case 'COST_AVERAGE':
      return $t('project.costAverage')
    default:
      switch (saleDetail.value?.costBasisType) {
        case 'MANUAL':
          return t('project.manualAssignedBy', { name: saleDetail.value?.user?.name })
        case 'RULE':
          return `Rule: ${saleDetail.value?.specificRule?.name}`
        case 'FIFO':
          return $t('project.fifo')
        case 'COST_AVERAGE':
          return $t('project.costAverage')
        case 'WAITING':
          return ''
        default:
          break
      }
      break
  }
  return ''
})

const onCheckSaleDetailDialog = () => {
  showSaleDetailDialog.value = !showSaleDetailDialog.value
}

// eslint-disable-next-line no-unused-vars
const onSaveSaleDetail = async () => {
  try {
    ElMessage.success(t('message.saveSuccess'))
    showSaleDetailDialog.value = false
  } catch (error: any) {
    ElMessage.error(error.message)
  }
}

const onOpenAssignmentMessageBox = () => {
  changeAssignmentMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const fetchSaleDetail = async () => {
  try {
    dialogLoading.value = true
    const { data } = await ValuationApi.getBrokerSaleDetail(entityId.value, props.currentData?.saleId)
    saleDetail.value = data
  } catch (error: any) {
    console.log(error)
  } finally {
    dialogLoading.value = false
  }
}

const onSelectInventory = () => {
  selectInventoryDialogRef.value?.onCheckSelectInventoryDialog()
}

const onCancelAssignment = () => {
  changeAssignmentMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onChangeAssignment = async () => {
  try {
    changeLoading.value = true
    if (saleDetail?.value?.costBasisType === 'MANUAL') {
      await ValuationApi.switchSaleInventoryToRule(entityId.value, {
        saleId: saleDetail.value.saleId
      })
    } else if (
      (saleDetail?.value?.costBasisType === 'RULE' || saleDetail?.value?.costBasisType === 'WAITING') &&
      !saleDetail.value?.inventoryList?.length
    ) {
      changeAssignmentMessageBoxRef.value?.onCheckMessageBoxDialog()
      onSelectInventory()
      return
    } else {
      await ValuationApi.switchSaleInventoryToManual(entityId.value, {
        saleId: saleDetail.value.saleId
      })
    }
    ElMessage.success(t('message.saveSuccess'))
    changeAssignmentMessageBoxRef.value?.onCheckMessageBoxDialog()
    await fetchSaleDetail()
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    changeLoading.value = false
  }
}

defineExpose({ onCheckSaleDetailDialog })

watch(
  [() => showSaleDetailDialog.value, () => props.currentData],
  async () => {
    if (showSaleDetailDialog.value && !isEmpty(props.currentData)) {
      await fetchSaleDetail()
    }
  },
  { immediate: true, flush: 'post' }
)
</script>

<style lang="scss">
.elv-valuation-sale-detail-dialog {
  width: 980px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-valuation-sale-detail-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      padding-left: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 20px;
    display: flex;
    min-height: 260px;
    flex-direction: column;
    align-items: flex-start;
  }

  .elv-valuation-sale-detail-dialog-content {
    min-height: 260px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .elv-valuation-sale-detail-title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h4 {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 2px;
    }

    p {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #aaafb6;
      font-family: 'Barlow';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      span {
        margin-left: 8px;
        padding-left: 8px;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: 1px;
          height: 12px;
          background: #edf0f3;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  .elv-valuation-sale-detail-info {
    border-radius: 3px;
    border: 1px solid #ced7e0;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;

    li {
      display: flex;
      min-width: 140px;
      box-sizing: border-box;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      flex-shrink: 0;
      align-self: stretch;
      border-right: 1px solid #edf0f3;

      span {
        color: #666d74;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      p {
        color: #000;
        font-family: 'Barlow';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }

  .elv-valuation-sale-detail-inventory-title {
    color: #000;
    font-family: 'Plus Jakarta Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 2px;

    p {
      color: #aaafb6;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .elv-valuation-sale-detail-inventory-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;

    .elv-valuation-sale-detail-inventory-header-left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }

  .elv-valuation-sale-detail-inventory {
    width: 100%;
  }

  .elv-valuation-sale-detail-inventory-desc {
    p {
      color: #aaafb6;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      span {
        padding-left: 8px;
        margin-left: 8px;
        font-family: 'Barlow';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: 1px;
          height: 12px;
          background: #edf0f3;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  .elv-valuation-sale-detail-inventory-select {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    .el-button.elv-button {
      font-size: 12px;
      font-weight: 500;
    }
  }

  .el-dialog__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 20px;

    .el-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      width: 113px;
      background: #1753eb;
      border-radius: 22px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #fff;
      border: 0px;
    }
  }
}

.elv-valuation-sale-detail-empty-content.elv-result {
  margin-top: 10px;

  .elv-result-description {
    margin-top: 12px;
    color: #000;
    font-family: 'Plus Jakarta Sans';
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}
</style>
