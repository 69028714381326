<template>
  <div class="elv-costbasis-rule-header">
    <div class="left">{{ ruleList.length + 2 }} {{ t('project.nav.rules') }}</div>
    <div class="right">
      <div class="add-button">
        <elv-button
          type="primary"
          round
          width="100"
          height="30"
          class="elv-financials-header-main-right__addRule"
          @click="openAddRuleDialog"
          ><SvgIcon name="source-add" width="14" height="14" class="icon" />{{ t('button.addRule') }}</elv-button
        >
      </div>
    </div>
  </div>
  <!-- <ElvMessageBox
    ref="ruleMessageBoxExecutingRef"
    :show-cancel-button="inventoryCostBasisStatus !== 'DOING'"
    :confirm-button-text="t('button.yes')"
    :cancel-button-text="t('button.cancel')"
    :title="t('report.rulesAreExecuting')"
    @onConfirmEvent="onConfirmExecutingEvent"
    @onCancelEvent="onCancelExecutingEvent"
  >
    <template #content>
      <span class="elv-confirm-info-header__title">{{
        inventoryCostBasisStatus !== 'DOING' ? t('message.executeRuleCostBasisMessage') : t('message.pleaseWaitRules')
      }}</span></template
    >
  </ElvMessageBox> -->
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
// import { ElMessage } from 'element-plus'
// import { useEntityStore } from '@/stores/modules/entity'

defineProps({
  ruleList: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(['onOpenAddRuleDialog'])

// const ruleMessageBoxExecutingRef = ref()
// const executeLoading = ref(false)
const { t } = useI18n()
// const entityStore = useEntityStore()

// const inventoryCostBasisStatus = computed(() => entityStore.entityDetail?.inventoryCostBasisStatus)

// const openExecuteRulesConfirm = () => {
//   if (inventoryCostBasisStatus !== 'DOING') {
//     ruleMessageBoxExecutingRef.value?.onCheckMessageBoxDialog()
//   } else {
//     ElMessage.warning(t('message.sourceInExecuting'))
//   }
// }

// const onCancelExecutingEvent = () => {
//   ruleMessageBoxExecutingRef.value?.onCheckMessageBoxDialog()
// }

// const onConfirmExecutingEvent = () => {
//   ruleMessageBoxExecutingRef.value?.onCheckMessageBoxDialog()
//   if (inventoryCostBasisStatus !== 'DOING') {
//     // executeRules()
//   }
// }

const openAddRuleDialog = () => {
  emit('onOpenAddRuleDialog')
}
</script>
<style lang="scss" scoped>
.elv-costbasis-rule-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .right {
    display: flex;

    .execute-button {
      display: flex;
      align-items: center;
      cursor: default;
      margin-right: 6px;

      .elv-reports-source-rule-button__execute {
        color: #1e2024;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        height: 27px;
        display: flex;
        align-items: center;
        cursor: pointer;
        border: 0px;
        padding: 0px;

        .el-icon.is-loading {
          margin-right: 0px;

          svg {
            width: 16px;
            height: 16px;
            margin-right: 0px;
          }
        }

        &.is-disabled {
          cursor: default;
        }

        &:hover,
        &:focus {
          background-color: #fff;
          color: #1753eb;

          .elv-reports-source-rule-button__execute-icon {
            fill: #1753eb;
          }
        }

        svg {
          margin-right: 6px;
        }

        span {
          display: flex;
          align-items: center;

          img {
            width: 12px;
            height: 12px;
            display: block;
            margin-right: 6px;
            animation: loading-rotate 2s linear infinite;
          }
        }
      }
    }

    .add-button {
      .elv-financials-header-main-right__addRule {
        padding: 0 12px;

        .icon {
          fill: #fff;
          margin-right: 4px;
        }
      }
    }
  }
}
</style>
